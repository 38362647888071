import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { transparentize } from "polished"

import NavBar from "../NavBar"

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: ${transparentize(0.1, "white")};
  backdrop-filter: saturate(180%) brightness(230%) contrast(170%) blur(6px);
  /* height: ${({ theme: { spacing } }) => spacing.xxl}; */

  svg {
    max-height: 2.5rem;
  }
`

const Header = ({ siteTitle }) => (
  <StyledHeader>
    <NavBar />
  </StyledHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
