import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { ThemeProvider } from "styled-components"

import { Theme, GlobalStyles } from "../style"

const BareLayout = ({ children }) => (
  <ThemeProvider theme={Theme}>
    <Helmet>
      <link rel="stylesheet" href="https://use.typekit.net/fqx1ysk.css" />
    </Helmet>
    <GlobalStyles />
    {children}
  </ThemeProvider>
)

BareLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BareLayout
