import React from "react"
import { darken, lighten } from "polished"
import { up } from "styled-breakpoints"
import styled from "styled-components"

import Logo from "../../images/logo_fumbleducks.inline.svg"
import LogoNoSubline from "../../images/logo_fumbleducks_nosubline.inline.svg"

const StyledHeader = styled.h1`
  margin: 0 auto;
  text-align: center;

  font-size: 1rem;
  line-height: 1;

  margin: 0;
  padding: 0;

  svg {
    max-width: 80vw;
    ${up("sm")} {
      max-width: 70vw;
    }
    ${up("md")} {
      max-width: 50vw;
    }
    ${up("lg")} {
      max-width: 30vw;
    }
    width: 100%;

    .foreground {
      fill: ${({ theme: { colors } }) => colors.highlight};
      fill: url(#logo-gradient);
      transition: fill 1s ease-out;
    }
  }

  #logo-gradient {
    --color-stop-1: ${({ theme: { colors } }) =>
      lighten(0.05, colors.highlight)};
    --color-stop-2: ${({ theme: { colors } }) => colors.highlight};
    --color-stop-3: ${({ theme: { colors } }) =>
      darken(0.05, colors.highlight)};
  }
`

const Brand = ({ showSubline = true, center = true }) => (
  <StyledHeader>
    {showSubline ? (
      <Logo preserveAspectRatio={`x${center ? "Mid" : "Min"}YMid`} />
    ) : (
      <LogoNoSubline preserveAspectRatio={`x${center ? "Mid" : "Min"}YMid`} />
    )}
  </StyledHeader>
)

export default Brand
