import React from "react"
import styled from "styled-components"

import Brand from "../Brand"
import Container from "../Container"
import { NavLink } from "../Links"

const StyledNav = styled(Container).attrs({
  as: "nav",
})`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme: { spacing } }) => spacing.xs} 0;

  .nav-item {
    margin-left: ${({ theme: { spacing } }) => spacing.lg};
  }
  /* width: 100%;
  background-color: white;
  border-bottom: 1px solid salmon;
  box-shadow:
    0 2px 4px rgba(0, 0, 0, .2),
    0 4px 8px rgba(0, 0, 0, .15),
    0 8px 16px rgba(0, 0, 0, .1),
    0 16px 32px rgba(0, 0, 0, .05); */
`

const NavBar = () => (
  <StyledNav>
    <NavLink to="/">
      <Brand showSubline={false} center={false} />
    </NavLink>
    <nav>
      <NavLink className="nav-item" to="/about">
        About
      </NavLink>
      <NavLink className="nav-item" to="/issues">
        All Issues
      </NavLink>
      <NavLink className="nav-item" to="/social-media">
        Social Media
      </NavLink>
    </nav>
  </StyledNav>
)

export default NavBar
