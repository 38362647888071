import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { ThemeProvider } from "styled-components"

import Footer from "../Footer"
import Header from "../Header"
import { Theme, GlobalStyles } from "../style"

// import "./layout.css"

const Layout = ({ children, showNavigation = true }) => (
  <ThemeProvider theme={Theme}>
    <Helmet>
      <link rel="stylesheet" href="https://use.typekit.net/fqx1ysk.css" />
    </Helmet>
    <GlobalStyles />
    {showNavigation && <Header />}
    {children}
    <Footer />
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
