import styled, { css } from "styled-components"
import { up } from "styled-breakpoints"

const Container = styled.div`
  padding: ${({ theme: { spacing } }) => spacing.default};

  ${({ fluid, theme: { containers } }) => {
    if (fluid)
      return css`
        width: 100%;
      `
    return css`
      width: ${containers.xs};
      margin-left: auto;
      margin-right: auto;

      ${up("sm")} {
        max-width: ${containers.sm};
      }

      ${up("md")} {
        max-width: ${containers.md};
      }

      ${up("lg")} {
        max-width: ${containers.lg};
      }

      ${up("xl")} {
        max-width: ${containers.xl};
      }
    `
  }}
`

export default Container
