import { createGlobalStyle } from "styled-components"
import { darken, modularScale, readableColor } from "polished"

export const getModular = x => modularScale(x, "1em", "majorThird")

export const getDuration = speed => ({ theme: { animations } }) =>
  `${speed / animations.speedFactor}s`

export const Theme = {
  spacing: {
    xxs: getModular(-3),
    xs: getModular(-2),
    sm: getModular(-1),
    default: getModular(0),
    md: getModular(1),
    lg: getModular(2),
    xl: getModular(3),
    xxl: getModular(4),
  },
  colors: {
    brand: "#ff4500", // lush lava
    // highlight: "#7fffd4", // aqua menthe
    // highlight: "#00cc88", // aqua menthe
    highlight: "#00ffaa", // aqua menthe
    accent: "#191970", // phantom blue
    dark: "black",
    light: "white",
    error: "#F52727",
    success: "#15FF64",
  },
  animations: {
    speedFactor: 1,
  },
  breakpoints: {
    xs: "0px",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
  },
  containers: {
    xs: "100%",
    sm: "540px",
    md: "720px",
    lg: "960px",
    xl: "1140px",
  },
}

export const GlobalStyles = createGlobalStyle`
  html {
    background-color: black;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, white 50%, black 50%);
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    font-family: courier-prime, 'Courier New', Courier, monospace;
    font-weight: 400;
    font-style: normal;
    background-color: white;
    margin: 0;
    margin-top: 3.5rem;
    margin-bottom: 10rem;
    min-height: 80%;
    min-height: 80vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    word-wrap: break-word;
    font-kerning: normal;
    -moz-font-feature-settings: "kern", "liga", "clig", "calt";
    -ms-font-feature-settings: "kern", "liga", "clig", "calt";
    -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
    font-feature-settings: "kern", "liga", "clig", "calt";
  }
  button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;

    background: transparent;

    color: inherit;
    font: inherit;

    line-height: normal;

    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    -webkit-appearance: none;
    appearance: none;

    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }
  }
  ::-moz-selection {
    background-color: ${Theme.colors.highlight};
    color: ${readableColor(Theme.colors.highlight, "white", "black")};
  }
  ::selection {
    background-color: ${Theme.colors.highlight};
    color: ${readableColor(Theme.colors.highlight, "white", "black")};
  }
  a,
  a:link {
    color: ${darken(0.085, Theme.colors.highlight)};
    text-decoration: none;
    box-shadow: inset 0 -0.055em 0 ${Theme.colors.highlight};
    transition: box-shadow ${getDuration(0.2)}, color ${getDuration(0.25)};
  }
  a:visited {
    color: ${darken(0.085, Theme.colors.highlight)};
    box-shadow: inset 0 -0.055em 0 ${darken(0.125, Theme.colors.highlight)};
  }
  a:hover {
    color: white;
    box-shadow: inset 0 -1.15em 0 ${Theme.colors.highlight};
  }
  a:active {
    color: white;
    box-shadow: inset 0 -1.15em 0 ${darken(0.125, Theme.colors.highlight)};
  }

  hr {
    height: 2px;
    border: none;
    background-color: ${Theme.colors.highlight}
  }
`
