import React from "react"
import styled from "styled-components"
import { darken } from "polished"
import { Link } from "gatsby"

import { getDuration } from "../style"

const NavLink = styled(props => <Link {...props} />)`
  &:link,
  &:visited {
    color: ${({ theme: { colors } }) => darken(0.085, colors.highlight)};
    text-decoration: none;
    box-shadow: none;
    transition: color ${getDuration(0.25)};
  }
  &:hover,
  &:active {
    color: ${({ theme: { colors } }) => darken(0.125, colors.highlight)};
  }
`

export default NavLink
