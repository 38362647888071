import React from "react"
import styled from "styled-components"

import Container from "../Container"
import { NavLink } from "../Links"

const StyledFooter = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 10rem;
  padding: ${({ theme: { spacing } }) => spacing.default} 0;
  z-index: -1;
  background-color: black;
  color: white;
`

const StyledFooterContent = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`

const FooterNavList = styled.ul`
  margin: 0;
  list-style-type: none;
`

const Footer = () => (
  <StyledFooter>
    <StyledFooterContent>
      © {new Date().getFullYear()}
      {` `}
      <NavLink to="/">The Fumble Ducks</NavLink>
      <nav>
        <FooterNavList>
          <li>
            <NavLink to="/imprint">Imprint</NavLink>
          </li>
          <li>
            <NavLink to="/privacy">Privacy</NavLink>
          </li>
          <li>
            <NavLink to="/contact">Contact</NavLink>
          </li>
        </FooterNavList>
      </nav>
    </StyledFooterContent>
  </StyledFooter>
)

export default Footer
